import { EXCHANGE_RATE, ROOM_TYPE } from "./dataset/common";
import { AlwaysBBType } from "./recoil/GameOption";
import { fixedNumber, toEasternNumeral } from "./utils/common";
import {ExchangeRateItem} from "./recoil/ExchangeRateList";

let exchangeData:EXCHANGE_RATE|null = null;
let chipPriceUSD:number|null = null;

const fixed:{[key:string]:number} = {
    USD:1000,
    SGD:1000,
    KRW:1,
    JPY:1,
    PHP:1,
    THB:1,
    HKD:1,
    TWD:1,
    VND:1,
    MYR:1,
    IDR:1,
    RMB:1,
}

export function currencyCeil(symbol:string, currencyAmount:number){
    if(!fixed) throw "currencyRound 1 error "+symbol;
    if(!fixed[symbol]) throw "currencyRound 2 error "+symbol;
    return Math.ceil(currencyAmount * fixed[symbol]) / fixed[symbol];
}

export function isInit(){
    return exchangeData != null;
}

export function getExchangeRate(currency:string):number{
    if(!exchangeData) return 0;
    return exchangeData[currency] || 0;
}

export function setExchangeRate(chipAmountOneUSD:number, rates:EXCHANGE_RATE){
    exchangeData = rates;
    chipPriceUSD = 1/chipAmountOneUSD;
}

export function currencyToChip(symbol:string, currencyAmount:number, isRound?:boolean){
    if(!exchangeData) throw "currencyToChip 1 error "+symbol;
    if(!exchangeData[symbol]) throw "currencyToChip 2 error "+symbol;
    if(!chipPriceUSD) throw "currencyToChip 3 error "+symbol;

    const negative = currencyAmount < 0 ? -1 : 1;
    currencyAmount = Math.abs(currencyAmount);

    let MathFunc = isRound ? Math.round : Math.floor;

    const perOneUSD = Math.ceil((1000000 / exchangeData[symbol]) * 100000)/100000 * (currencyAmount/1000000);
    return negative * MathFunc(perOneUSD / chipPriceUSD);
}

export function chipToCurrency(symbol:string, chipAmount:number, fix?:number, isRound?:boolean){
    if(!exchangeData) throw "chipToCurrency 1 error "+symbol;
    if(!exchangeData[symbol]) throw "chipToCurrency 2 error "+symbol;
    if(!chipPriceUSD) throw "currencyToChip 3 error "+symbol;

    const negative = chipAmount < 0 ? -1 : 1;
    chipAmount = Math.abs(chipAmount);

    const usd = Math.floor(chipAmount * chipPriceUSD * 10000) / 10000;
    const fixedMulti = fix ? fix : fixed[symbol]||1;

    return negative * Number(Math.floor((usd * exchangeData[symbol]) * (fixedMulti)) / fixedMulti);
}

export function fiatToFiat(from:string, to:string, amount:number){
    if(!exchangeData) throw "fliatToFiat 1 error "+from;
    if(!exchangeData[from]) throw "fliatToFiat 2 error "+from;
    if(!exchangeData[to]) throw "fliatToFiat 3 error "+to;

    const usd = amount / exchangeData[from];
    const fixedMulti = fixed[to]||1;
    return Number(Math.floor((usd * exchangeData[to]) * (fixedMulti)) / fixedMulti);
}

let userCurrency = "KRW";
export function setUserCurrency(symbol:string){
    userCurrency = symbol || "KRW";
}

export function UserCurrency(){
    return userCurrency;
}

export function UserToChip(currencyAmount:number){
    return currencyToChip(userCurrency, currencyAmount);
}

export function UserToCurrency(chipAmount:number){
    return chipToCurrency(userCurrency, chipAmount);
}

export function UserCurrencyMark(){
    return CurrencyMark(userCurrency);
}

export function CurrencyMark(currency:string){
    switch(currency){
        case "KRW": return "₩";
        case "USD": return "$";
        case "JPY": return "¥";
        case "PHP": return "₱";
        case "THB": return "฿";
        case "SGD": return "S$";
        default: return "";
    }
}

export function PrintPointOrFiat(num:number, formatter:null|((num:number)=>string)){
    let rr = localStorage.getItem("toggleViewFiat") || "true";
    let is_fiat = rr === "true";
    if(is_fiat){
        let mark = UserCurrencyMark();
        let currency = UserToCurrency(num);

        return mark + currency.toLocaleString();
    }

    if(formatter){
        return formatter(num);
    }else{
        return num.toLocaleString();
    }
}

export function convertChipToTargetCurrencyData(chipAmount: number, exchangeRate: ExchangeRateItem, fix?: number){
    let rr = localStorage.getItem("toggleViewFiat") || "true";
    let is_fiat = rr === "true";
    if(is_fiat){
        let mark = UserCurrencyMark();
        const symbol = UserCurrency();

        if(!exchangeData) throw "chipToCurrency 1 error "+symbol;
        if(!exchangeRate.data[symbol]) throw "chipToCurrency 2 error "+symbol;
        if(!chipPriceUSD) throw "currencyToChip 3 error "+symbol;

        const negative = chipAmount < 0 ? -1 : 1;
        chipAmount = Math.abs(chipAmount);

        const usd = Math.floor(chipAmount * chipPriceUSD * 10000) / 10000;
        const fixedMulti = fix ? fix : fixed[symbol]||1;

        return {
            currency: mark,
            amount: negative * Number(Math.floor((usd * exchangeRate.data[symbol]) * (fixedMulti)) / fixedMulti)
        }
    }else{
        return {
            currency: "",
            amount: chipAmount
        }
    }
}

export function convertChipToTargetCurrencyString(chipAmount: number, exchangeRate: ExchangeRateItem, fix?: number){
    const result = convertChipToTargetCurrencyData(chipAmount, exchangeRate, fix);
    let rr = localStorage.getItem("toggleViewFiat") || "true";
    let is_fiat = rr === "true";
    if(is_fiat){
        return result.currency + result.amount.toLocaleString();
    }else{
        return result.amount.toLocaleString();
    }
}
