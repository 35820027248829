import styled from "styled-components";
import Flex1 from "./common/Flex";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MyInfo } from "../dataset";
import { requestMyGroupVaultList, requestMyInfo } from "../api";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { globalLoadingState } from "../recoil/Loading";
import { MEDIA_MOBILE_MINI } from "../hooks/useScreenOrientation";
import { chipToCurrency, UserCurrency, UserCurrencyMark, UserToChip, UserToCurrency } from "../conversionChip";

const Wrapper = styled.div`
  width: 100%;
  min-width: 360px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
  position: relative;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
`;

const BuyinNum = styled.div`
  font-size: 12px;
  color: #FFF;
  display: flex;

  opacity: 0.8;

  img{
    width: 15px;
    margin-right: 2px;
  }
`

const MinMaxBuyIn = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;

  > div {
    /* display: flex; */
    color: #FFF;
    font-size: 10px;
    font-weight: 600;

    .item-name {
      display: inline-block;
      color: #FFF;
      font-size: 10px;
      font-weight: 600;
      opacity: 0.5;
      margin-right: 2px;
    }
  }
`;

const CurrencyMark = styled.div`
  font-size: 32px;
  color: #FFF;
  background-color: transparent;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2D2D2D;

  input {
    width: 230px;
    flex: 1;
    font-size: 32px;
    color: #FFF;
    padding: 8px;
    background-color: transparent;
    outline: none;
    border: none;

    &::placeholder {
      color: #FFF;
      opacity: 0.5;
    }
  }
`;

const InputWrapperSmallRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;

  img{
    width: 15px;
  }

  div {
    font-size: 15px;
    color: #FFF;
    padding: 5px;
    background-color: transparent;
    outline: none;
    border: none;
  }
`;


const ShortcutButtons = styled.div`
  display: flex;
  gap: 4px;
  padding: 16px 0;

  > div {
    flex: 1;
    padding: 12px;
    border-radius: 6px;
    background: #1F1F1F;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    cursor: pointer;
    @media ${MEDIA_MOBILE_MINI} {
      font-size: 11px;
    }
    &.max {
      flex: initial;
    }

    >div{
      font-weight: 400;
      font-size: 12px;
      color: #CCC;
      opacity: 0.5;
    }
  }
`;

const Reentry = styled.div`
  margin: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  >div{
    font-size: 15px;
    color: white;
  }
`

const BalanceInfo = styled.div`
  margin: 26px 0 42px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .row {
    display: flex;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .item-name {
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
      opacity: 0.5;
      margin-left: 4px;
      margin-right: 2px;
    }
  }
`;

const VaultBox = styled.div`
  padding: 10px;
  margin: 10px 0 20px 0;
  background-color: #1E1E1E;
  border-radius: 5px;
  display: flex;
  align-items: center;
  >div{
    &:first-child{
      flex: 1;
      font-size: 14px;
      color: white;
    }
  }
`;

const VaultBuyinButton = styled.div`
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #ECB322 23.2%, #C38424 98.42%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  cursor: pointer;

  &:hover{
    opacity: 0.8;
  }

  &:active{
    opacity: 0.6;
  }
`;

const BuyInButton = styled.div<{
  disabled?: boolean
}>`
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;

  ${p => p.disabled && `
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.20);
    cursor: initial;
  `};

  &:hover{
      opacity: 0.8;
    }

    &:active{
      opacity: 0.6;
    }
`;

const ExchangeInfo = styled.div`
  /* position: absolute; */
  /* right: 10px; */
  /* top: 22px; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width:15px;
    margin-right: 3px;
  }
  div{
    font-size: 11px;
    margin-left: 2px;
    font-weight: bold;
  }
`

interface BuyInModalProps {
  smallBlind: number;
  bigBlind: number;
  minBuyIn: number;
  maxBuyIn: number;
  groupId: number;
  roomId: number;
  currentStack: number;
  waitBuyin: boolean;
  onClickBuyIn: (buyIn: number, vaultId: number) => void;
  onClickReentryOpen: () => void;
}

function BuyInModal(
  {
    smallBlind,
    bigBlind,
    minBuyIn,
    maxBuyIn,
    groupId,
    roomId,
    waitBuyin,
    currentStack,
    onClickBuyIn,
    onClickReentryOpen
  }: BuyInModalProps
) {
  const { t } = useTranslation();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [value, setValue] = useState<string>('0');
  const [myInfo, setMyInfo] = useState<MyInfo>();
  const [vault, setVault] = useState<{ vaultId: number, amount: number, buyin: number, hands: number } | null>(null);
  const [groupVaults, setGroupVaults] = useState<{ vaultId: number, amount: number, buyin: number, hands: number }[]>([]);

  const minBuyInFiat = useMemo<number>(() => {
    return UserToCurrency(minBuyIn);
  }, [minBuyIn]);

  const maxBuyInFiat = useMemo<number>(() => {
    return UserToCurrency(maxBuyIn);
  }, [maxBuyIn]);

  const numVal = useMemo<number>(() => {
    console.log("value >> ",value)
    return parseInt(value.replace(/,/g,""));
  }, [value]);

  const numChip = useMemo<number>(() => {
    if(isNaN(numVal)) return 0;
    return UserToChip(numVal);
  }, [numVal]);

  const onChangeValue = useCallback((v:any) => {
    if(isNaN(Number(v))) return ;

    if(UserCurrency() == "KRW"){
      setValue((Math.floor(v / 100) * 100).toString());
    }else if(UserCurrency() == "JPY"){
      setValue((Math.floor(v / 10) * 10).toString());
    }else{
    }
  }, []);

  const isValidValue = useMemo<boolean>(() => {
    if (vault) return true;

    if (currentStack > 0) {
      if (numVal > maxBuyInFiat) return false;
      if (numVal > 0) return true;
    }

    if (numChip < 10000) return false;
    let myMoney = myInfo?.money || 0;

    if (!value || numVal < minBuyInFiat || numVal > maxBuyInFiat || numChip < minBuyIn || numChip > maxBuyIn) {
      return false;
    } else if (!myInfo || myMoney < numVal) {
      return false;
    }

    return true;
  }, [numVal, minBuyIn, maxBuyIn, numChip, myInfo?.money, vault, currentStack, minBuyInFiat, maxBuyInFiat]);

  useEffect(() => {
    setGlobalLoading(true);
    requestMyInfo().then((v: any) => {
      setMyInfo(v.info)
    }).finally(() => {
      setGlobalLoading(false);
    });

    requestMyGroupVaultList().then((v: any) => {
      v.list.forEach((item: any) => {
        if (item.groupId === groupId && item.roomId === roomId) {
          setVault(item);
        }
      });

      let blind = `${smallBlind}-${bigBlind}`;
      setGroupVaults(v.list.filter((w: any) => w.blind.join("-") === blind));
    });
  }, []);

  const handleInput = useCallback((e: any) => {
    if (e.nativeEvent?.data && (/[^0-9]/g).test(e.nativeEvent.data)) {
      e.preventDefault();
      return;
    }

    const v = (e.target?.value ?? 0).toString().replace(/[^0-9]/g, '')
    setValue(v === '' ? '' : Number(v).toLocaleString())
  }, []);

  useEffect(()=>{
    let q = setTimeout(function(){
      onChangeValue(numVal);
    },1000);

    return ()=>clearTimeout(q);
  },[numVal]);

  const handleBuyIn = () => {
    if (!isValidValue)
      return;

    let num = numVal;
    if(UserCurrency() == "KRW"){
      num = Math.floor(num / 100) * 100;
    }else if(UserCurrency() == "JPY"){
      num = Math.floor(num / 10) * 10;
    }else{
    }

    setValue(num.toLocaleString());

    onClickBuyIn && onClickBuyIn(Number(num), vault?.vaultId || 0);
  }

  const handleSum = (num: number) => {
    let newValue;

    if (num === -1) {
      newValue = myInfo?.money ?? 0;
    } else if (num === -2) {
      newValue = 0;
    } else {
      newValue = Number(numVal || 0) + Number(num);
    }
    console.log("handleSum", num, numVal, myInfo?.money, newValue, maxBuyInFiat); 

    const v = Math.min(maxBuyInFiat, newValue);
    setValue(v.toLocaleString());
    onChangeValue(v);
  }

  return <Wrapper>
    <Title>{t('바이인')}</Title>
    {vault ? <>
      <Reentry>
        <div>{t("이전 플레이에서 남은 칩으로 게임을 시작합니다.")}</div>
        <InputWrapper>
          <img src="/images/ic_buyin_chips.svg" />
          <input defaultValue={Number(vault.amount).toLocaleString()} disabled />
        </InputWrapper>
      </Reentry>
    </> : <>
      <ExchangeInfo>
        <div style={{display:"flex", alignContent:"center",lineHeight:"14px"}}>
          {t("변환 비율")}
          {" "}
          
          {UserCurrencyMark()}
          {1}
          <div style={{margin:"0 4px", fontSize:"13px", transform: "translateY(-2px)"}}>{"≈"}</div>
          <img src="/images/ic_buyin_chips.svg" />
          {(UserToChip(100000)/100000)}
        </div>
      </ExchangeInfo>
      <div>
        {waitBuyin == false && groupVaults.length > 0 ? <VaultBox>
          <div>{t("정산금으로 바이인을 할 수 있습니다.")}</div>
          <VaultBuyinButton onClick={onClickReentryOpen}>{t('바이인')}</VaultBuyinButton>
        </VaultBox> : null}
      </div>
      <MinMaxBuyIn>
        <div>
          <div className="item-name">MIN</div>
          BB X {minBuyIn / bigBlind}
          <BuyinNum>
            <img src="/images/ic_buyin_chips.svg" />{minBuyIn.toLocaleString()}
          </BuyinNum>
        </div>
        <Flex1 />
        <div>
          <div className="item-name">MAX</div>
          BB X {maxBuyIn / bigBlind}
          <BuyinNum style={{justifyContent:"flex-end"}}>
            <img src="/images/ic_buyin_chips.svg" />{maxBuyIn.toLocaleString()}
          </BuyinNum>
        </div>
      </MinMaxBuyIn>
      <InputWrapper>
        {/* <img src="/images/ic_buyin_chips.svg" /> */}
        <CurrencyMark>{UserCurrencyMark()}</CurrencyMark>
        <input value={numVal || ''} placeholder={t("충전 수량")} onInput={handleInput} />
      </InputWrapper>
      <InputWrapperSmallRight>
        <img src="/images/ic_buyin_chips.svg" />
        <div>{numChip.toLocaleString()}</div>
      </InputWrapperSmallRight>
      <ShortcutButtons>
        <div onClick={() => handleSum( chipToCurrency(UserCurrency(), 5000, 1000) )}>
          {UserCurrencyMark()}{UserToCurrency(5000).toLocaleString()}
          <div>({UserToChip(UserToCurrency(5000)).toLocaleString()})</div>
        </div>
        <div onClick={() => handleSum( chipToCurrency(UserCurrency(), 10000, 1000) )}>
          {UserCurrencyMark()}{UserToCurrency(10000).toLocaleString()}
          <div>({UserToChip(UserToCurrency(10000)).toLocaleString()})</div>
        </div>
        <div onClick={() => handleSum( chipToCurrency(UserCurrency(), 100000, 1000) )}>
          {UserCurrencyMark()}{UserToCurrency(100000).toLocaleString()}
          <div>({UserToChip(UserToCurrency(100000)).toLocaleString()})</div>
        </div>
        <div className="max" onClick={() => handleSum(-1)}>
          {t('최대')}
        </div>
        {/* <div className="max" onClick={() => handleSum(-2)}>{t('정정')}</div> */}
      </ShortcutButtons>
      <BalanceInfo>
        <div className="row">
          <span className="item-name">{t('보유 금액')}</span>
          {UserCurrencyMark()}{(myInfo?.money ?? 0).toLocaleString()}
          <span className="item-name"> - {t('바이인')}</span>
          {UserCurrencyMark()}{numVal.toLocaleString()}
        </div>
        <div className="row">
          <div className="item-name">= {t('밸런스')}</div>
          {UserCurrencyMark()}{((myInfo?.money ?? 0) - numVal).toLocaleString()}
        </div>
      </BalanceInfo>
    </>}
    <BuyInButton disabled={!isValidValue} onClick={handleBuyIn}>{t('바이인')}</BuyInButton>
  </Wrapper>
}

export default BuyInModal
