import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MyInfo} from "../dataset";
import {requestMyGroupVaultList, requestMyInfo} from "../api";
import {useTranslation} from "react-i18next";
import {useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import { UserCurrencyMark, UserToCurrency } from "../conversionChip";
import DigitsColorWrapper from "./DigitsColor";
import HandsCount from "./HandsCount";

const Wrapper = styled.div`
  width: 100%;
  min-width: 320px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
`;

const Title = styled.div`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
`;


const List = styled.ul`
    margin-top: 10px;
    padding: 0;
    list-style: none;
    max-height: 400px;
    overflow-y: scroll;

    >li{
        background-color: rgba(31, 31, 31, 1);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        padding: 20px 15px;
        gap: 10px;

        >.amount{
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title{
                font-size: 16px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.5);
            }

            .money{
              color: #999;
              font-size: 16px;
              margin-top: 10px;
              display: flex;
              font-weight: bold;
              gap:3px;
              >div{
                display: flex;
                align-items: center;
                img{
                  width: 16px;
                }

                color: #fff;
              }
              .fiat{
              }
            }

            
        }

        .right{
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .hands{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: rgba(55, 255, 37, 1);
            background-color: rgba(55, 255, 37, 0.1);
            padding: 5px 10px;
            border-radius: 6px;
        }
    }
`;

const BuyInButton = styled.div`
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    cursor: pointer;
`;

interface BuyInModalProps {
  groupId: number;
  roomId: number;
  smallBlind:number;
  bigBlind:number;
  onClickBuyIn: (vaultId: number) => void;
}

function VaultBuyInModal(
  {
    groupId,
    roomId,
    smallBlind,
    bigBlind,
    onClickBuyIn
  }: BuyInModalProps
) {
  const {t} = useTranslation();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [myInfo, setMyInfo] = useState<MyInfo>();
  const [vault, setVault] = useState<{amount:number, buyin:number, hands:number}[]>([]);

  useEffect(() => {
    setGlobalLoading(true);
    requestMyInfo().then((v: any) => {
      setMyInfo(v.info)
    }).finally(() => {
      setGlobalLoading(false);
    });

    requestMyGroupVaultList().then((v: any) => {
      let blinds = `${smallBlind}-${bigBlind}`;

      const q = v.list.filter((item: any) => {
        let blind = item.blind.join('-');
        return blinds == blind;
      })
      setVault([...q]);
    });
  }, []);

  return <Wrapper>
    <Title>{t('로비정산 바이인')}</Title>
    <List>
        {vault.map((item: any) => {
            return <li key={item.vaultId}>
                <div className="amount">
                    <div className="title">{t("정산금액")}</div>
                    <div className="money">
                      <div>
                        <img src="/images/ic_buyin_chips.svg" style={{marginRight:"5px"}} />
                        {Number(item.amount).toLocaleString()}
                      </div>
                      {" | "}
                      <div className="fiat">{UserCurrencyMark()}{" "}<DigitsColorWrapper number={UserToCurrency(item.amount)} fontSize={13}/></div>
                    </div>
                </div>
                <div className="right">
                  <div className="hands">
                    <HandsCount number={item.hands} text={<><span style={{marginRight:"5px"}}>{t('핸드 수')}</span></>} />
                  </div>
                  <BuyInButton onClick={()=>onClickBuyIn(item.vaultId)}>
                      {t("바이인")}
                  </BuyInButton>
                </div>
            </li>
        })}
    </List>
  </Wrapper>
}

export default VaultBuyInModal