import styled from "styled-components";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import useDialog from "../../hooks/useDialog";
import { useRecoilState } from "recoil";
import { gameOptionState } from "../../recoil/GameOption";
import { ROOM_TYPE } from "../../dataset";
import { PrintAlwaysBB } from "../PrintAlwaysBB";
import { UserCurrencyMark, UserToCurrency } from "../../conversionChip";
import DigitsColorWrapper from "../DigitsColor";
import HandsCount from "../HandsCount";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 14px 0;

  .message {
    line-height: 150%;
  }

  .tip {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
    margin-top: 8px;
    line-height: 1.5;
  }

  .border {
    width: 100%;
    min-height: 1px;
    background-color: #2D2D2D;
    margin: 16px 0;
  }
`;

const Hand = styled.div<{
  lack: boolean
}>`
  display: inline-flex;
  padding: 8px 10px;
  border-radius: 8px;
  background: rgba(255, 37, 37, 0.20);
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;

  ${p => !p.lack && `
    background: rgba(106, 255, 42, 0.2);
  `};

  img {
    margin-right: 4px;
  }

  span {
    margin-right: 2px;
    font-weight: 400;
  }
`;

const Settlement = styled.div`
  .stack {
    font-size: 12px;
    margin-bottom: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .settle {
    font-size: 14px;
    margin-top: 16px;
  }

  >div{
    span.name {
      opacity: 0.5;
      margin-right: 4px;

    }

    span.amount{
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      img {
        margin-right: 4px;
        width: 15px;
      }

      div{
        margin-right: 4px;
      }

      .fiat{
        color: gray;
      }
    }

    span.diff {
      opacity: 1 !important;
      padding-left: 4px;

      &[data-loss="true"] {
        color: #FF2525;
      }

      &[data-loss="false"] {
        color: #37FF25;
      }
    }
  }
`;

function LeaveSettlement(
  {
    hands,
    stack,
    buyin,
    lossPercentage,
    settle,
    winAmount,
    maxHands,
    cuttingList,
    lack,
    bb,
    roomType
  }: {
    roomType: ROOM_TYPE,
    hands: number,
    stack: number,
    buyin: number,
    winAmount: number,
    lossPercentage: number,
    bb: number,
    settle: number,
    maxHands: number,
    cuttingList: { handCount: number, lossPercentage: number }[],
    lack: boolean,
  }
) {
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const { t } = useTranslation();
  const history = useHistory();
  const { closeDialogByPopupId } = useDialog();

  const lossAmount = useMemo(() => {
    return Math.floor(winAmount * (lossPercentage / 100));
  }, [winAmount, lossPercentage]);

  // const handleGotoLobby = () => {
  //   history.push('/lobby');
  //   closeDialogByPopupId("leaveSettlement")
  // }

  return <Wrapper>
    <Hand lack={lack}>
      <HandsCount number={hands} text={<>
        {
          lack && (
            <i className="fas fa-exclamation-triangle" style={{marginRight:"5px"}} />
          )
        }
        <span>{t('핸드 수')}</span></>} />
    </Hand>
    <Settlement>
      <div className="stack">
        <span className="name">{t('총 바이인')}</span>
        <span className="amount">
          <img src="/images/ic_buyin_chips.svg" />
          <div>{(buyin || 0).toLocaleString()}</div>
          <div className="fiat">({UserCurrencyMark()}<DigitsColorWrapper color="gray" digitScale={0.9} number={UserToCurrency(buyin || 0)} />)</div>
        </span>
      </div>
      <div className="stack">
        <span className="name">{t('현재 보유 칩')}</span>
        <span className="amount">
          <img src="/images/ic_buyin_chips.svg" />
          <div>{(stack || 0).toLocaleString()}</div>
          <div className="fiat">({UserCurrencyMark()}<DigitsColorWrapper color="gray" digitScale={0.9} number={UserToCurrency(stack || 0)} />)</div>
        </span>
      </div>
      <div className="stack">
        <span className="name">{t('이익금')}</span>

        <span className="amount">
          <img src="/images/ic_buyin_chips.svg" />
          <div>{(winAmount || 0).toLocaleString()}</div>
          <div className="fiat">({UserCurrencyMark()}<DigitsColorWrapper color="gray" digitScale={0.9} number={UserToCurrency(winAmount || 0)} />)</div>
        </span>

        {/* {winAmount > 0 ? <span className="diff" data-loss="false">
                +{PrintAlwaysBB(roomType, setting.alwaysBB, winAmount|| 0, bb, undefined, 0)}
            </span> : 0} */}
      </div>
      {lossAmount > 0 && (<div className="stack">
        <span className="name">{t('핸드 패널티')}</span>

        <span className="diff amount" data-loss="true">
          <img src="/images/ic_buyin_chips.svg" />
          <div>-{(lossAmount || 0).toLocaleString()}</div>
          <div className="fiat">(-{UserCurrencyMark()}<DigitsColorWrapper color="gray" digitScale={0.9} number={UserToCurrency(lossAmount || 0)} />)</div>
        </span>
      </div>)}
      <div className="settle">
        <span className="name">{t('정산 금액')} :</span>

        <span className="amount">
          <img src="/images/ic_buyin_chips.svg" />
          <div>{(settle || 0).toLocaleString()}</div>
          <div className="fiat">({UserCurrencyMark()}<DigitsColorWrapper color="gray" digitScale={0.9} number={UserToCurrency(settle || 0)} />)</div>
        </span>


      </div>
    </Settlement>
    <div className="border" />
    <div className="message">
      {t("정산은 대기실에서 가능합니다.")}<br />
      {t("이용 중인 방에서 나가시겠습니까?")}
    </div>
  </Wrapper>
}

export default LeaveSettlement;