function HandsCount({
    number,
    text,
}: {
    number: number,
    text: JSX.Element
}) {
    if (isNaN(number)) return <>{number}</>;

    const defaultStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
    
    return <span style={number >= 31 && number <= 49 ? { "color": "yellow", ...defaultStyle } : defaultStyle}>
        {text}
        {Number(number || 0).toLocaleString()}
    </span>
}

export default HandsCount;